.btn-high-immuno {
    background-color: rgb(246, 71, 71);
    color: white;
    border: none;
  }
  
  
  .btn-high-immuno:hover,
  .btn-high-immuno:focus,
.btn-high-immuno:active {
    background-color: rgb(155, 0, 0);
    color: white;
  }
  
  .btn-low-immuno {
    background-color: rgb(3, 160, 3);
    color: white;
    border: none;
  }
  
  .btn-low-immuno:hover,
  .btn-low-immuno:focus,
.btn-low-immuno:active
  {
    background-color: darkgreen;
    color: white;
  }

  .btn-predict {
    
    /*font-size: 1.30rem;
    padding: 5px 10px; */
    background-color: rgb(86, 122, 240);
    color: white;
    border: none;
  }


  .btn-predict:hover,
  .btn-predict:focus,
.btn-predict:active {
    background-color: rgb(26, 26, 164);
    color: white;
  }